import React, { useEffect } from "react";

const SuccessScreen = ({ customerData }) => {
   useEffect(() => {
      window.scrollTo({ top: 450, behavior: 'smooth' })
   }, [])

   return (
      <React.Fragment>
         <p style={{marginBottom: 10}}>Cancellation form - Step 4 / 4</p>
         <h2 style={{marginTop: 0}}>Your cancellation request has been submitted <i class="fa-solid fa-circle-check"></i></h2>

         <p>For now there is nothing more to do. You will receive an email shortly confirming your request to cancel your policy with us.</p>

         <p>If we require any more information from you we will contact you on the phone number or email address that we have registered on your account. Once your cancellation has been finalised, you will also receive an email confirming this.</p>

         <p>We're sorry to see you go, but we hope that you made the most of your Appliance Sure policy and we'd love to welcome you back again in the future.</p>
         
         <br/>

         <a className="standard-button primary" href='/'>Go to homepage</a>
      </React.Fragment>
   )
}

export default SuccessScreen