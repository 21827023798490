import axios from "axios";
import React, { useEffect, useState } from "react";
import Error from "../../../global/error"
import ReCAPTCHA from "react-google-recaptcha";
import { detect } from 'detect-browser'

const AlternativeIdentification = ({
   setStage,
   customerData,
   updateCustomerData
}) => {
   // Variables
   const cancellation_reasons = [
      ["I have cover elsewhere", "Has cover elsewhere"],
      ["I changed my mind", "Changed mind"],
      ["I don't use the policy", "Does not use the policy"],
      ["The policy is too expensive", "Too expensive"],
      ["I am moving house", "Moving house"],
      ["I have complaints about the policy", "Complaints about policy"],
      ["Other", "Other" ]
   ]

   const [errors, setErrors] = useState({
      firstName: false,
      lastName: false,
      emailAddress: false,
      phoneNumber: false,
      postcode: false,
      recaptcha: false
   })

   const [recaptchaVerified, setRecaptchVerified] = useState(false)

   useEffect(() => {
      window.scrollTo({top: 450, behavior: 'smooth'})
   }, [])

   const createDigitalFingerprint = async (fingerprint) => {
      const ip_lookup = await axios.get("https://geolocation-db.com/json/")
      const browser = detect();

      updateCustomerData({
         ...customerData,
         digitalFingerprint: {
            ipAddress: ip_lookup.data.IPv4,
            location: `${ip_lookup.data.state}, ${ip_lookup.data.country_name}`,
            operatingSystem: browser.os,
            browser: browser.name,
            timestamp: new Date(),
            reCaptchaFingerprint: fingerprint
         }
      })
   }

   const handleRobotVerification = (e) => {
      if(e === null) {
         setRecaptchVerified(false)
      } else {
         setRecaptchVerified(true)
         createDigitalFingerprint(e)
         setErrors({
            ...errors,
            recaptcha: false
         })
      }
   }

   const handleChange = (event) => {
      const name = event.target.name;
      let val = event.target.value;
      if(name === 'agreedPrivacy') val = event.target.checked;

      updateCustomerData({
         ...customerData,
         [name]: val
      })

      setErrors({
         ...errors,
         [name]: false
      })
   }

   const handleFillInReason = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      updateCustomerData({
         ...customerData,
         reasonForCancel: {
            ...customerData.reasonForCancel,
            [name]: val
         }
      })
   }

   const handleDataValidation = () => {
      let errors = 0;
      let errors_object = {}

      if(customerData.firstName === '') {
         errors++
         errors_object.firstName = true
      }

      if(customerData.lastName === '') {
         errors++
         errors_object.lastName = true
      }

      if(customerData.emailAddress.length < 6 || customerData.emailAddress.includes("@") === false || customerData.emailAddress.includes(".") === false) {
         errors++
         errors_object.emailAddress = true
      }

      if(customerData.phoneNumber.length < 7) {
         errors++
         errors_object.phoneNumber = true
      }

      if(customerData.postcode.length < 5 || customerData.postcode.length > 8) {
         errors++
         errors_object.postcode = true
      }

      if(recaptchaVerified === false) {
         errors++
         errors_object.recaptcha = true
      }

      if(errors === 0) {
         return true
      } else {
         setErrors({
            ...errors,
            ...errors_object
         })

         window.scrollTo({top: 450, behavior: 'smooth'})
         
         return false
      }
   }

   const submitCancellation = async () => {
      if(handleDataValidation() === true) {
         await axios({
            method: 'post',
            url: 'https://api.appliancesure.com/one/customers/cancel/submitCancellation',
            params: {
               source: 'Online form'
            },
            data: customerData
         })
         .then((value) => {
            if(value.data.success === true) {
               setStage(4)
            } else {
               alert("Error")
            }
         })
         .catch((err) => {
            alert("Error here")
            console.log(err)
         })
      }
   }

   return (
      <React.Fragment>
         <button className="plain-text-button" onClick={() => setStage(2)}><i class="fa-solid fa-arrow-left"></i> Go back</button>

         <br/>
         <br/>

         <p style={{marginBottom: 10}}>Cancellation form - Step 3 / 4</p>
         <h2 style={{marginTop: 0}}>Tell us about yourself</h2>

         <p>As we were not able to identify you or find your policy by your policy number, we will need to take a few other details to identify you. We may also need to contact you again if we require any additional information.</p>

         <form onChange={handleChange} style={{marginTop: 60}}>
            <div className="form-2col">
               <span>
                  <label className="form-label">First name</label>
                  <input
                     className="form-input"
                     id="firstName"
                     name="firstName"
                     value={customerData.firstName}
                     placeholder="Please enter your first name"
                  />


                  {
                     errors.firstName ? (
                        <Error message="Please enter your first name "/>
                     ) : null
                  }
               </span>
               
               <span>
                  <label className="form-label">Last name</label>
                  <input
                     className="form-input"
                     id="lastName"
                     name="lastName"
                     value={customerData.lastName}
                     placeholder="Please enter your last name"
                  />

                  {
                     errors.lastName ? (
                        <Error message="Please enter your last name "/>
                     ) : null
                  }
               </span>
            </div>

            <label className="form-label">Email address</label>
            <input
               className="form-input"
               id="emailAddress"
               name="emailAddress"
               value={customerData.emailAddress}
               placeholder="Please enter your email address"
            />

            {
               errors.emailAddress ? (
                  <Error message="Enter a valid email address"/>
               ) : null
            }

            <label className="form-label">Phone number</label>
            <input
               className="form-input"
               id="phoneNumber"
               name="phoneNumber"
               value={customerData.phoneNumber}
               placeholder="Please enter your phone number"
            />

            {
               errors.phoneNumber ? (
                  <Error message="Enter a valid telephone number"/>
               ) : null
            }

            <div className="form-2col">
               <span>
                  <label className="form-label">Postcode</label>
                  <input
                     className="form-input"
                     id="postcode"
                     name="postcode"
                     value={customerData.postcode}
                     placeholder="Please enter your postcode"
                  />

                  {
                     errors.postcode ? (
                        <Error message="Enter a valid UK postcode"/>
                     ) : null
                  }
               </span>
            </div>
         </form>

         <form onChange={handleFillInReason}>
            <h2>Give us feedback</h2>

            <label className="form-label">Reason for cancellation</label>
            <select 
               className="form-input"
               name="reason"
            >
               <option value="" selected disabled>Choose a reason</option>
               {cancellation_reasons.map(reason => (
                  <option value={reason[1]}>{reason[0]}</option>
               ))}
            </select>

            { 
               customerData.reasonForCancel.reason === 'Other' ? (
                  <textarea
                     placeholder="Write your feedback"
                     value={customerData.reasonForCancel.ifOther}
                     name="ifOther"
                     id="ifOther"
                  />
               ) : null
            }
         </form>

         <h3>Submit your cancellation request?</h3>

         <p>As we were not able to bring up your policy details, we will need to contact you via telephone to verify your identity and policy. This is to protect anyone else from accessing your data. We aim to do this withing 2 business days.</p>

         <ReCAPTCHA
            sitekey={process.env.GATSBY_RECAPTCHA_KEY}
            onChange={(e) => handleRobotVerification(e)}
         />

         {
            errors.recaptcha ? (
               <React.Fragment>
                  <div style={{height: 30}}/>
                  <Error message="Please verify you are a human"/>
               </React.Fragment>
            ) : null
         }
         
         <br/>

         <button className="standard-button primary" onClick={submitCancellation}>Yes, submit</button> &nbsp;&nbsp;
         <a className="standard-button secondary" href="/">No, I have changed my mind</a>
      </React.Fragment>
   )
}

export default AlternativeIdentification