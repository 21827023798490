import React, { useEffect, useState } from "react";
import axios from "axios";
import Error from "../../../global/error";
import ReCAPTCHA from "react-google-recaptcha";
import { detect } from 'detect-browser'
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";

const CustomerLookupScreen = ({
   setStage,
   customerData,
   updateCustomerData
}) => {
   useEffect(() => {
      window.scrollTo({ top: 450, behavior: 'smooth' })
   }, [])

   const LoadingProgress = () => {
      const { promiseInProgress } = usePromiseTracker();

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Fetching policy details...</h3>
         </div>
      )
   }

   const [lookupAttempts, setLookupAttempts] = useState(0)

   const [errors, setErrors] = useState({
      serviceNumber: false,
      postcode: false,
      surname: false,
      recaptcha: false,
      lookupError: false
   })

   const [recaptchaVerified, setRecaptchVerified] = useState(false)

   const createDigitalFingerprint = async (fingerprint) => {
      const ip_lookup = await axios.get("https://geolocation-db.com/json/")
      const browser = detect();

      updateCustomerData({
         ...customerData,
         digitalFingerprint: {
            ipAddress: ip_lookup.data.IPv4,
            location: `${ip_lookup.data.state}, ${ip_lookup.data.country_name}`,
            operatingSystem: browser.os,
            browser: browser.name,
            timestamp: new Date(),
            reCaptchaFingerprint: fingerprint
         }
      })
   }

   const handleFillInForm = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      updateCustomerData({
         ...customerData,
         [name]: val
      })

      setErrors({
         ...errors,
         [name]: false
      })
   }

   const handleRobotVerification = (e) => {
      if(e === null) {
         setRecaptchVerified(false)
      } else {
         setRecaptchVerified(true)
         createDigitalFingerprint(e)
         setErrors({
            ...errors,
            recaptcha: false
         })
      }
   }

   const handleDataValidation = () => {
      let errors = 0;
      let errors_object = {}

      if(customerData.postcode.length < 5 || customerData.postcode.length > 8) {
         errors++
         errors_object.postcode = true
      }

      if(customerData.serviceNumber.length < 5) {
         errors++
         errors_object.serviceNumber = true
      }

      if(customerData.lastName === '') {
         errors++
         errors_object.lastName = true
      }

      if(recaptchaVerified === false) {
         errors++
         errors_object.recaptcha = true
      }

      if(errors === 0) {
         return true
      } else {
         setErrors({
            ...errors,
            ...errors_object
         })

         window.scrollTo({top: 450, behavior: 'smooth'})
         
         return false
      }
   }

   const lookupCustomer = async (event) => {
      event.preventDefault();

      if(handleDataValidation() === true) {
         try {
            await trackPromise(
               axios({
                  method: 'get',
                  url: "https://api.appliancesure.com/one/customers/cancel/cancellationLookup",
                  params: {
                     serviceNumber: customerData.serviceNumber,
                     lastName: customerData.lastName,
                     postcode: customerData.postcode
                  }
               })
               .then((value) => {
                  const data = value.data;

                  console.log(data)

                  if(data.success === true) {
                     let appliancesCovered = ''
                     for (let i = 0; i < data.data.Appliance_s_Covered.length; i++) {
                        const el = data.data.Appliance_s_Covered[i];
                        
                        if(i === 0) {
                           appliancesCovered += el
                        } else if(i + 1 === data.data.Appliance_s_Covered.length) {
                           appliancesCovered += ` & ${el}`
                        } else {
                           appliancesCovered += `, ${el}`
                        }
                     }

                     updateCustomerData({
                        ...customerData,
                        lastName: data.data['Customer.Last_Name'],
                        firstName: data.data['Customer.First_Name'],
                        serviceNumber: data.data.Name,
                        postcode: data.data['Address.Name'],
                        addressOneLiner: `${data.data['Address.Address_1']}, ${data.data['Address.Town']}, ${data.data['Address.Name']}`,
                        emailAddress: data.data['Customer.Email'] || '',
                        dateJoined: new Date(data.data.Create_Date).toLocaleDateString('en-GB'),
                        price: data.data.Price,
                        paymentFrequency: data.data.Payment_Frequencyy1,
                        policyType: data.data.Policy_Type,
                        appliancesCovered: appliancesCovered,
                        identityConfirmed: true,
                        id: data.data.id,
                        customer_id: data.data.Customer.id
                     })
                     setStage(3)
                  } else {
                     setLookupAttempts(lookupAttempts++)
                     setErrors({
                        ...errors,
                        lookupError: true
                     })

                     if(lookupAttempts > 1) {
                        setStage(3.5)
                     }
                  }
               })
               .catch((err) => {
                  setLookupAttempts(lookupAttempts+1)
                  setErrors({
                     ...errors,
                     lookupError: true
                  })

                  if(lookupAttempts > 1) {
                     setStage(3.5)
                  }
                  console.log(err)
               })
            )
         }

         catch (err) {
            setLookupAttempts(lookupAttempts+1)
            setErrors({
               ...errors,
               lookupError: true
            })

            if(lookupAttempts > 1) {
               setStage(3.5)
            }
            console.log(err)
         }
      }
   }

   return (
      <React.Fragment>
         <LoadingProgress/>
         <button className="plain-text-button arrow-left" onClick={() => setStage(1)}>Go back</button>

         <br/>
         <br/>

         <p style={{marginBottom: 10}}>Cancellation form - Step 2 / 4</p>
         <h2 style={{marginTop: 0}}>Let's find your policy</h2>

         <h3>Do you know the following information?</h3>

         <form onChange={handleFillInForm}>
            <label htmlFor="serviceNumber" className="form-label">Service number</label>
            <input
               className="form-input"
               placeholder="Enter your service number"
               value={customerData.serviceNumber}
               name="serviceNumber"
               id="serviceNumber"
            />

            {
               errors.serviceNumber ? (
                  <Error message="Enter your service number"/>
               ) : null
            }

            <label htmlFor="postcode" className="form-label">Postcode</label>
            <input
               className="form-input"
               placeholder="Enter your postcode"
               value={customerData.postcode}
               name="postcode"
               id="postcode"
            />

            {
               errors.postcode ? (
                  <Error message="Enter a valid UK postcode"/>
               ) : null
            }

            <label htmlFor="lastName" className="form-label">Surname</label>
            <input
               className="form-input"
               placeholder="Enter your surname"
               value={customerData.lastName}
               name="lastName"
               id="lastName"
            />

            {
               errors.lastName ? (
                  <Error message="Enter your surname"/>
               ) : null
            }

            <ReCAPTCHA
               sitekey={process.env.GATSBY_RECAPTCHA_KEY}
               onChange={(e) => handleRobotVerification(e)}
            />

            {
               errors.recaptcha ? (
                  <React.Fragment>
                     <div style={{height: 30}}/>
                     <Error message="Please verify you are a human"/>
                  </React.Fragment>
               ) : null
            }

            <br/>

            {
               errors.lookupError ? (
                  <div className="info-warning-box">
                     <p>The details that you entered do not match with our records, please check again or proceed to use alternative identification.</p>
                  </div>
               ) : null
            }

            <button className="standard-button primary" onClick={lookupCustomer}>Look up my account</button>
         </form>

         <br/>

         <h3>I don't know this information</h3>

         <p>We can still process your cancellation without this information, but we may need to contact you to verify your identity.</p>

         <button className="standard-button secondary" onClick={() => setStage(3.5)}>Alternative identification</button>

      </React.Fragment>
   )
}

export default CustomerLookupScreen