import axios from "axios";
import React, { useEffect, useState } from "react";
import Error from "../../../global/error"
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";

const ConfirmDetailsScreen = ({
   setStage,
   customerData,
   updateCustomerData
}) => {
   // Variables
   const cancellation_reasons = [
      ["I have cover elsewhere", "Has cover elsewhere"],
      ["I changed my mind", "Changed mind"],
      ["I don't use the policy", "Does not use the policy"],
      ["The policy is too expensive", "Too expensive"],
      ["I am moving house", "Moving house"],
      ["I have complaints about the policy", "Complaints about policy"],
      ["Other", "Other" ]
   ]

   const SubmissionProgress = () => {
      const { promiseInProgress } = usePromiseTracker();

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Submitting cancellation request...</h3>
         </div>
      )
   }

   // States
   const [showEmailInput, setShowEmailInput] = useState(false)
   const [errors, setErrors] = useState({
      emailAddress: false,
      agreedPrivacy: false
   })

   useEffect(() => {
      const email = customerData.emailAddress;

      if(email.length < 6 || email.includes("@") === false || email.includes(".") === false) {
         setShowEmailInput(true)
      }

      window.scrollTo({top: 450, behavior: 'smooth'})
   }, [])

   const handleChange = (event) => {
      const name = event.target.name;
      let val = event.target.value;
      if(name === 'agreedPrivacy') val = event.target.checked;

      updateCustomerData({
         ...customerData,
         [name]: val
      })

      setErrors({
         ...errors,
         [name]: false
      })
   }

   const handleFillInReason = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      updateCustomerData({
         ...customerData,
         reasonForCancel: {
            ...customerData.reasonForCancel,
            [name]: val
         }
      })
   }

   const handleAddEmailAddress = async () => {
      try {
         await axios({
            method: 'put',
            url: 'https://api.appliancesure.com/one/customers/manage/addEmailAddress',
            data: {
               id: customerData.customer_id,
               email_address: customerData.emailAddress
            }
         })
         .then(() => {
            updateCustomerData({
               ...customerData,
               emailAdded: {
                  success: true
               }
            })
         })
         .catch((err) => {
            updateCustomerData({
               ...customerData,
               emailAdded: {
                  success: false,
                  reason: err
               }
            })
         })
      }

      catch (err) {
         updateCustomerData({
            ...customerData,
            emailAdded: {
               success: false,
               reason: err
            }
         })
      }
   }

   const handleSubmitCancellation = async () => {
      await trackPromise(
         axios({
            method: 'post',
            url: 'https://api.appliancesure.com/one/customers/cancel/submitCancellation',
            params: {
               source: 'Online form'
            },
            data: customerData
         })
         .then((value) => {
            if(value.data.success === true) {
               setStage(4)
            } else {
               alert("Error")
            }
         })
         .catch((err) => {
            alert("Error here")
            console.log(err)
         })
      )
   }

   const submitCancellation = async (event) => {
      event.preventDefault();

      if(showEmailInput) {
         let errors = 0;
         let errors_object = {}

         const email = customerData.emailAddress;
         if(email.length < 6 || email.includes("@") === false || email.includes(".") === false) {
            errors++
            errors_object.emailAddress = true
         }

         if(customerData.agreedPrivacy === false) {
            errors++
            errors_object.agreedPrivacy = true
         }

         if(errors === 0) {
            await handleAddEmailAddress();
            await handleSubmitCancellation();
         } else {
            setErrors({
               ...errors,
               ...errors_object
            })
         }
      } else {
         await handleSubmitCancellation()
      }
   }

   return (
      <React.Fragment>
         <SubmissionProgress/>
         <button className="plain-text-button" onClick={() => window.location.reload()}>Start again <i class="fa-solid fa-arrow-rotate-right"></i></button>

         <br/>
         <br/>
         
         <p style={{marginBottom: 10}}>Cancellation form - Step 3 / 4</p>
         <h2 style={{marginTop: 0}}>Confirm that your details are correct</h2>

         <div className="address-container completed">
            <label className="form-label" htmlFor="serviceNumber">Service number</label>
            <input
               className="form-input"
               id="serviceNumber"
               name="serviceNumber"
               disabled={true}
               value={customerData.serviceNumber}
            />

            <label className="form-label" htmlFor="accountHolder">Account holder</label>
            <input
               className="form-input"
               id="accountHolder"
               name="accountHolder"
               disabled={true}
               value={customerData.firstName + " " + customerData.lastName}
            />

            <label className="form-label" htmlFor="addressOneLiner">Address</label>
            <input
               className="form-input"
               id="addressOneLiner"
               name="addressOneLiner"
               disabled={true}
               value={customerData.addressOneLiner}
            />

            <label className="form-label" htmlFor="policyType">Policy</label>
            <input
               className="form-input"
               id="policyType"
               name="policyType"
               disabled={true}
               value={customerData.policyType}
            />

            <label className="form-label" htmlFor="appliancesCovered">Appliance(s) covered</label>
            <textarea
               className="form-input"
               id="appliancesCovered"
               name="appliancesCovered"
               disabled={true}
               value={customerData.appliancesCovered}
            />

            <label className="form-label" htmlFor="price">Price</label>
            <input
               className="form-input"
               id="price"
               name="price"
               disabled={true}
               value={`£${customerData.price} per ${customerData.paymentFrequency === 'Annually' ? 'year' : 'month'}`}
            />

            <label className="form-label" htmlFor="dateJoined">Date joined</label>
            <input
               className="form-input"
               id="dateJoined"
               name="dateJoined"
               disabled={true}
               value={customerData.dateJoined}
            />
         </div>

         <form onChange={handleFillInReason}>
            <h2>Give us feedback</h2>

            <label className="form-label">Reason for cancellation</label>
            <select 
               className="form-input"
               name="reason"
            >
               <option value="" selected disabled>Choose a reason</option>
               {cancellation_reasons.map(reason => (
                  <option value={reason[1]}>{reason[0]}</option>
               ))}
            </select>

            { 
               customerData.reasonForCancel.reason === 'Other' ? (
                  <textarea
                     placeholder="Write your feedback"
                     value={customerData.reasonForCancel.ifOther}
                     name="ifOther"
                     id="ifOther"
                  />
               ) : null
            }
            
         </form>

         {
            showEmailInput ? (
               <React.Fragment>
                  <br/>
                  <br/>
                  <p className="warning-notice" style={{marginBottom: -30}}>Missing email address</p>
                  <h3>We need to send you confirmation via email</h3>
                  <p>It appears that we either don't have an email address on file for you or its invalid. In order for us to send you confirmations about the cancellation of your contract with us, we need to take one.</p>

                  <label className="form-label" htmlFor="emailAddress">Email address</label>
                  <input
                     className="form-input"
                     id="emailAddress"
                     name="emailAddress"
                     value={customerData.emailAddress}
                     placeholder="Please enter your email address"
                     onChange={handleChange}
                  />

                  {
                     errors.emailAddress ? (
                        <React.Fragment>
                           <Error message="Please provide a valid email address"/>
                        </React.Fragment>
                     ) : null
                  }
                  
                  <div className='checkbox-form-container'>
                     <input
                        type="checkbox"
                        className='checkbox-trigger invisible'
                        id="agreedPrivacy"
                        name="agreedPrivacy"
                        checked={customerData.agreedPrivacy}
                        onChange={handleChange}
                     />
                     <label htmlFor='agreedPrivacy' className="checkbox"/>

                     <span style={{textAlign: 'left'}}>
                        <label htmlFor='agreedPrivacy' className='form-label'>I confirm I've read the cancellation policy</label>
                        <span>Failure to understand your obligations under your policy agreement could result in early cancellation fees if you are still in contract.</span>
                     </span>
                  </div>

                  {
                     errors.agreedPrivacy ? (
                        <React.Fragment>
                           <div style={{height: 40}}/>
                           <Error message="It is mandatory to read and agree to our privacy policy when adding your email address"/>
                           <div style={{marginBottom: -40}}/>
                        </React.Fragment>
                     ) : null
                  }

                  <br/>
               </React.Fragment>
            ) : null
         }
         
         

         <h3>Are you sure that you want to cancel your policy?</h3>

         <p>In the majority of cases, we are able to cancel your contract without contacting you further. However, in some cases we will need to contact you for further information or if we believe that there is something you should be aware of.</p>
         
         <br/>

         <button className="standard-button primary" onClick={submitCancellation}>Yes, cancel</button> &nbsp;&nbsp;
         <a className="standard-button secondary" href="/">No, I have changed my mind</a>
      </React.Fragment>
   )
}

export default ConfirmDetailsScreen