import React, { useState } from "react";
import Error from "../../../global/error";

const ReadPolicyScreen = ({
   setStage,
   customerData,
   updateCustomerData
}) => {
   const [errors, setErrors] = useState(false)

   const handleChange = (event) => {
      const value = event.target.checked;

      updateCustomerData({
         ...customerData,
         hasReadPolicy: value
      })

      setErrors(false)
   }

   const goToNextPage = () => {
      if(customerData.hasReadPolicy === false) {
         setErrors(true)
      } else {
         setStage(2)
      }
   }

   return (
      <React.Fragment>
         <p style={{marginBottom: 10}}>Cancellation form - Step 1 / 4</p>
         <h2 style={{marginTop: 0}}>First, have you read the cancellation policy?</h2>

         <a className="external" href="/cancellation-policy" target="_blank">Click here to view</a>

         <br/>
         <br/>
         <br/>

         <div className='checkbox-form-container'>
            <input
               type="checkbox"
               className='checkbox-trigger invisible'
               id="has_read_policy"
               checked={customerData.hasReadPolicy}
               onChange={handleChange}
            />
            <label htmlFor='has_read_policy' className="checkbox"/>

            <span style={{textAlign: 'left'}}>
               <label htmlFor='has_read_policy' className='form-label'>I confirm I've read the cancellation policy</label>
               <span>Failure to understand your obligations under your policy agreement could result in early cancellation fees if you are still in contract.</span>
            </span>
         </div>

         {
            errors ? (
               <React.Fragment>
                  <div style={{height: 40}}/>
                  <Error message="It is mandatory to read the cancellation policy"/>
               </React.Fragment>
            ) : null
         }
         
         <br/>
         <br/>

         <button className="standard-button primary" onClick={goToNextPage}>Continue</button>
         
      </React.Fragment>
   )
}

export default ReadPolicyScreen