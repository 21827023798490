import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/global/breadcrumbs";
import Footer from "../components/global/footer";
import Navigation from "../components/global/navigation";
import SEO from "../components/global/seo";
import CancellationForm from "../components/local/cancellation-form";

// CSS
import '../styles.css'

const Cancellation = () => {
   return (
      <React.Fragment>
         <SEO
            title="Cancel your policy"
            slug="cancellation"
            metaDescription="Cancel your Appliance Sure policy with our quick and easy to use online form. We'll confirm your cancellation via email and follow up with any additional info."
            image=""
            titleTemplate="%s - Appliance Sure"
         />

         <Navigation/>

         <div className="content-hero-container">
            <div className="content-hero-content-container">
               <h1>Cancel your policy</h1>
               <p>We're sorry to see you go, but if you'd like to cancel the policy that you have with us, please use this page to do so. Make sure you've checked the cancellation policy before submitting your request.</p>
            </div>
         </div>

         <div className="standard-container">
            <div className="content-split-container narrow-width">
               <div className="content-container">
                  <Breadcrumbs
                     location="cancellation"
                  />
                  <CancellationForm/>
               </div>
               <div className="side-bar-container">
                  <div className="side-bar-entries">
                  <span>
                     <input
                        type="checkbox"
                        defaultChecked={true}
                        id="related-articles"
                        className="related-articles-trigger invisible"
                     />

                     <label className="related-articles-label" htmlFor="related-articles">Important documents</label>

                     <div className="related-articles-content">
                        <a href="/cancellation-policy">Cancellation policy</a>
                        <a href="/terms-conditions">Terms & conditions</a>
                     </div>
                  </span>
                  </div>
               </div>
            </div>
         </div>

         <Footer/>
      </React.Fragment>
   )
}

export default Cancellation