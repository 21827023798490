import React, { useState, useEffect } from "react"
import AlternativeIdentification from "./screens/alternative-id"
import ConfirmDetailsScreen from "./screens/confirm-details"
import CustomerLookupScreen from "./screens/customer-lookup"
import ReadPolicyScreen from "./screens/read-policy"
import SuccessScreen from "./screens/success"

const CancellationForm = () => {
   useEffect(() => {
      window.scrollTo({ top: 0 })
   }, [])

   // States
   const [stage, setStage] = useState(1)
   const [customerData, updateCustomerData] = useState({
      hasReadPolicy: false,
      serviceNumber: '',
      identityConfirmed: false,
      postcode: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
      policyType: '',
      addressOneLiner: '',
      appliancesCovered: '',
      agreedPrivacy: false,
      digitalFingerprint: {
         ipAddress: '',
         location: '',
         operatingSystem: '',
         browser: '',
         timestamp: '',
         reCaptchaFingerprint: ''
      },
      reasonForCancel: {
         reason: '',
         ifOther: ''
      },
      dateJoined: '',
      price: '',
      paymentFrequency: '',
      id: '',
      customer_id: ''
   })

   switch (stage) {
      case 1:
         return <ReadPolicyScreen
                  setStage={setStage}
                  customerData={customerData}
                  updateCustomerData={updateCustomerData}
               />
      
      case 2:
         return <CustomerLookupScreen
                  setStage={setStage}
                  customerData={customerData}
                  updateCustomerData={updateCustomerData}
               />
      
      case 3:
         return <ConfirmDetailsScreen
                  setStage={setStage}
                  customerData={customerData}
                  updateCustomerData={updateCustomerData}
               />

      case 3.5:
         return <AlternativeIdentification
                  setStage={setStage}
                  customerData={customerData}
                  updateCustomerData={updateCustomerData}
               />

      case 4:
         return <SuccessScreen
                  customerData={customerData}
               />
   }
}

export default CancellationForm